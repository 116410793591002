<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="分组" prop="configGroup" >
        <a-input v-model="form.configGroup" :disabled='formType == 2' placeholder="请输入分组" />
      </a-form-model-item>
      <a-form-model-item label="变量名"  prop="configName" >
        <a-input v-model="form.configName" :disabled='formType == 2' placeholder="请输入变量名" />
      </a-form-model-item>
      <a-form-model-item label="变量标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入变量标题" />
      </a-form-model-item>

      <a-form-model-item label="参数类型" prop="type">
        <a-radio-group v-model="form.type" button-style="solid">
          <a-radio-button v-for="(d, index) in  configTypeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="值" prop="configValue" >
        <a-textarea v-model="form.configValue" placeholder="请输入" v-if="form.type== 1 " />
        <editor id="configValueId" v-if="form.type == 2" v-model="form.configValue"  ></editor>
        <a-input-number v-if="form.type == 3" v-model="form.configValue" placeholder="请输入值"  :min='0' ></a-input-number>
        <a-upload v-if="form.type == 4"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload("configValue",info)'
                  :customRequest='(info) => fileUploadRequest("configValue",info)' v-model="form.configValue">
          <img v-if="form.type ==4 && form.configValue != null" :src='form.configValue' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>
        </a-upload>
      </a-form-model-item>


      <a-form-model-item label="描述" prop="description" >
        <a-input v-model="form.description" placeholder="请输入描述" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/app/config'
import Editor from '@/components/Editor'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      configTypeOptions: [{"value":1,"label":"文本框"},{"value":2,"label":"富文本"},{"value":3,"label":"数字"},{"value":4,"label":"图片"}],
      // 表单参数
      form: {
        id: null,

        configGroup: null,

        configName: null,

        title: null,

        description: null,

        configValue: null,

        configValueEn: null,

        type: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      uploadLoading:false,
      rules: {
        configGroup: [
          { required: true, message: '变量名不能为空', trigger: 'blur' }
        ],
        configName: [
          { required: true, message: '分组不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '变量标题不能为空', trigger: 'blur' }
        ],
        configValue: [
          { required: true, message: '值不能为空', trigger: 'blur' }
        ],

        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        configGroup: null,
        configName: null,
        title: null,
        description: null,
        configValue: null,
        configValueEn: null,
        type: 1,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getConfig({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {


      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          this.form.configValue = res[0].url;
        },
      });
    },
    beforeUploadEn: function (file) {
      var fileType = file.type;
      if (fileType.indexOf('image') < 0) {
        this.$message.warning('请上传图片');
        this.uploadLoading=false
        return false;
      }
      this.form.configValueEn=""
    },
    handleChangeEn(info) {

      let that = this
      this.uploadLoading = true;
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          that.form.configValueEn = fileName[0];//文件名
          that.uploadLoading = false;
        },
      });
      that.uploadLoading = false;

    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
