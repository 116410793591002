import request from '@/utils/request'


// 查询系统配置列表
export function listConfig(query) {
  return request({
    url: '/config/config/list',
    method: 'get',
    params: query
  })
}

// 查询系统配置分页
export function pageConfig(query) {
  return request({
    url: '/config/config/page',
    method: 'get',
    params: query
  })
}

// 查询系统配置详细
export function getConfig(data) {
  return request({
    url: '/config/config/detail',
    method: 'get',
    params: data
  })
}

// 新增系统配置
export function addConfig(data) {
  return request({
    url: '/config/config/add',
    method: 'post',
    data: data
  })
}

// 修改系统配置
export function updateConfig(data) {
  return request({
    url: '/config/config/edit',
    method: 'post',
    data: data
  })
}

// 删除系统配置
export function delConfig(data) {
  return request({
    url: '/config/config/delete',
    method: 'post',
    data: data
  })
}
